import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './projects.module.css';
import { UikWidget, UikHeadline, UikHeadlineDesc, UikInput, UikWidgetTable, UikButton, UikFormInputGroup } from '../../lib/index.js';
import fire from '../fire';


class Projects extends Component {
    
    constructor(props){
        super(props);

        this.state = {
            newprojectname: "",
            description: "",
            projects: []           
        }
    }

    componentWillMount(){
        let projectsRef = fire.database().ref('/projects').orderByKey().limitToLast(100);
        projectsRef.on('child_added', snapshot => {
            let project = { text: snapshot.val(), id: snapshot.key };
            console.log(project);
            this.setState({ projects: [project].concat(this.state.projects) });
        })
    }

    addProject = (e) => {
        fire.database().ref('/projects').push( 
            {name: this.state.newprojectname,
            description: this.state.description}

         );
        console.log("Adding project: " + this.state.newprojectname);
    }

    enterName = (e) => {
        console.log("Entering name:");
        console.log(e.currentTarget.value);
        this.setState({
            newprojectname: e.target.value
        })
    }

    enterDescription = (e) => {
        this.setState({
            description: e.target.value
        })
    }

    check = () => {
        console.log(this.state.projects);
    }  


    render() {
        const { isTourOpen, isShowingMore } = this.state;
       

        return (
        <div className={styles.projects}>
           <div className={styles.projectheader}>
                <UikHeadline style={{flex: 1}}>
                    Projects
                </UikHeadline>
                <UikButton primary>
                    Create Project
                </UikButton>
            </div>
          
            
            <UikWidget>
                <UikWidgetTable className={styles.projecttable}>
                    <thead>
                        <tr>
                        <th style={{width:'200px'}}>
                            Project Name
                        </th>
                        <th>
                            Description
                        </th>
                        <th style={{width:'100px', textAlign: 'right'}}>
                            Codes
                        </th>
                        <th style={{width:'100px', textAlign: 'right'}}>
                            Redeemed
                        </th>
                        <th style={{width:'100px', textAlign: 'right'}}>
                            Expired
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.projects.map((project, index) => (
                        <tr>
                        <td>
                            <a href="#">{project.text.name}</a>
                        </td>
                        <td>
                            {project.text.description}
                        </td>
                        <td style={{textAlign: 'right'}}>{project.text.codes}</td>
                        <td style={{textAlign: 'right'}}>{project.text.redeemed}</td>
                        <td style={{textAlign: 'right'}}>{project.text.expired}</td>
                    </tr>
                    ))}
                    </tbody>


                </UikWidgetTable>
            </UikWidget>
            
            <br/>
            
            <UikWidget padding>
                <UikFormInputGroup>
                    <UikInput
                        label="Project Name"
                        placeholder="e.g. My Project"
                        onChange={this.enterName}
                        />

                        <UikInput
                        label="Description"
                        placeholder="e.g. My first project"
                        onChange={this.enterDescription}
                        />


                    <UikButton primary onClick={this.addProject}>
                        Add Project
                    </UikButton>

                    <UikButton onClick={this.check}>
                        Test
                    </UikButton>
                </UikFormInputGroup>
            </UikWidget>
        </div>      
        )
  }
}


Projects.propTypes = {
    step: PropTypes.number,    
}

export default (Projects);
