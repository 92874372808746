import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './widget.module.css';

class Widget extends Component {
    
    render() {
        return (
            <div className={styles.widget}>
                {this.props.children}
            </div>      
        )
  }
}


Widget.propTypes = {
    step: PropTypes.number,    
}

export default (Widget);
