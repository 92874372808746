import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './navigation.module.css';

class Navigation extends Component {
    
    constructor(props){
        super(props);

        this.state = {
           selected: "projects"
        }

    }

    menuClick = (data) => {
        console.log(data);   
        this.setState({
            selected: data
        })       
    }



    render() {
        return (
            <div className={styles.navigation}>
            <a className={styles.logo} href="#"><h2>Inviteco.de</h2></a>
            <div className={styles.navbarspacer}>&nbsp;</div>
            <div className={styles.menuitems}>
                <a href="#" onClick={() => this.menuClick('dashboard')} className={classNames({[styles.active]: this.state.selected === "dashboard"})}>Dashboard</a>
                <a href="#" onClick={() => this.menuClick('analytics')} className={classNames({[styles.active]: this.state.selected === "analytics"})}>Analytics</a>
                <a href="#" onClick={() => this.menuClick('projects')} className={classNames({[styles.active]: this.state.selected === "projects"})}>Projects</a>
                <a href="#" onClick={() => this.menuClick('settings')} className={classNames({[styles.active]: this.state.selected === "settings"})}>Settings</a>
                
            </div>
            <div className={styles.searchbar}></div>
            <div className={styles.navbarspacer}>&nbsp;</div>
            <div className={styles.settings}>My Account</div>
        </div>      
        )
  }
}


Navigation.propTypes = {
    step: PropTypes.number,    
}

export default (Navigation);
