import React, { Component } from 'react';
import logo from './logo.svg';
import styles from './App.module.css';

import './lib/styles.css';
import Navigation from './modules/navigation/Navigation';
import Widget from './modules/ui/Widget';
import Projects from './modules/projects/Projects';




class App extends Component {
  render() {
    return (
      <div className={styles.stage}>
       <Navigation/>
       <Projects/> 
      </div>
    );
  }
}

export default App;
