import firebase from 'firebase';
  var config = {
    apiKey: "AIzaSyANloKgOtDPH9f_Cm2XmbP9HfxTCwpZzdo",
    authDomain: "invitecode-45358.firebaseapp.com",
    databaseURL: "https://invitecode-45358.firebaseio.com",
    projectId: "invitecode-45358",
    storageBucket: "invitecode-45358.appspot.com",
    messagingSenderId: "116160157596"
  };
var fire = firebase.initializeApp(config);
export default fire;
